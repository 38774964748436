import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard {
    private authService: AuthService = inject(AuthService);
    private router: Router = inject(Router);

    public canLoad(): Observable<boolean> {
        this.authService.getRole();

        if (this.authService.loggedIn()) {
            return of(true);
        }

        return this.authService.isAlive().pipe(
            catchError(() => {
                this.router.navigate(['/login']);
                return of(false);
            })
        );
    }

    public canActivate(): Observable<boolean> {
        return this.canLoad();
    }
}
